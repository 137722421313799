import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
        <ul className="icons">
            <li>
                <a
                    href="https://www.linkedin.com/in/danscott2150/"
                    className="icon fa-linkedin"
                    target="_blank"
                >
                    <span className="label">LinkedIn</span>
                </a>
            </li>
            <li>
                <a
                    href="https://github.com/dscottnu"
                    className="icon fa-github"
                    target="_blank"
                >
                    <span className="label">GitHub</span>
                </a>
            </li>
            <li>
                <a href="https://instagram.com/agentdanno" target="_blank" className="icon fa-instagram">
                    <span className="label">Instagram</span>
                </a>
            </li>
            <li>
                <a href="/static/DanScott-resume-Oct2023.pdf" target="_blank" className="icon fa-file-pdf-o">
                    <span className="label">Resume</span>
                </a>
            </li>
        </ul>
        <script src="https://unpkg.com/react/umd/react.production.min.js" crossorigin></script>

        <script
            src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"
            crossorigin></script>

        <script
            src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
            crossorigin></script>

        <script>var Alert = ReactBootstrap.Alert;</script>

    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
