import PropTypes from 'prop-types'
import React from 'react'
// import pic01 from '../images/pic01.jpg'
// import pic02 from '../images/pic02.jpg'
// import pic03 from '../images/pic03.jpg'

// import Portfolio from './Portfolio';


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Dan</h2>
          <span className="image main"><img src="/static/danscott_pic1.jpg" alt="" style={{float: 'right', width: '40%', marginLeft: 1 +'em'}} /></span>
          <h3 >Hi! I'm Dan!</h3>
          <p>
            I'm a self-taught full-stack web developer with a passion for learning new things and pursuing new opportunities!
          </p>

          <p>
            Despite starting my professional career in the Finance & Investments industry, about a decade ago I made a decision to shift career paths and began teaching myself to code. As my knowledge began to grow and I slowly gained confidence, I began to pick up freelance work. I quickly established myself as the go-to contractor for multiple small agency clients who frequently had a need for overflow work - and quickly scaled myself up to a pretty steady full-time freelance workload.
          </p>

          <p>
            In 2021 I made the decision to shift back to full-time employment, and began working as developer for an enterprise-grade educational non-profit, supporting their broader marketing & data anlytics teams. In this role, I have been leading the development and management of the organization's flagship website, as well as all web-based internal software, services, APIs, and internal toolings. I have also been the lead developer for a group of 3 junior developers, providing guidance, feedback, and mentoring.
          </p>

          <p>
            Looking to the future, I am always on the lookout for opportunities to continue to grow, learn, and contribute to organizations that are looking to make a positive impact in the world. I'm especially excited about the possibilities that will be unlocked by the growing adoption of AI-based technologies - and I am currently spending most of my downtime expanding my skills and knowledge in this area.
          </p>

          <h3 className="major">Hobbies &amp; Personal Life</h3>
          <p>
            In my free time I love to enjoy the outdoors - specifically camping, hiking, and fly-fishing. Over the past few
            years I've been exploring the New England region, and have especially fallen in love with the White Mountains as
            well as Northern Maine. I'm also an avid photographer, rarely leaving home without my camera in hand.
          </p>

          <div className="image main"><img src="/static/danscott_pic2.jpg" /></div>

          {close}
        </article>

        <article
          id="skills"
          className={`${this.props.article === 'skills' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Skills & Expertise</h2>

          <h3>Comfortable working with -</h3>
          <ul className="techskills">
            <li>JavaScript (ES6+)</li>
            <li>PHP</li>
            <li>WordPress Development</li>
            <li>API Creation & Integration</li>
            <li>Vue.js</li>
            <li>Git & GitHub</li>
            <li>Agile Scrum Workflows</li>
            <li>Webpack & Gulp</li>
            <li>SASS</li>
            <li>HTML & CSS</li>
          </ul>

          <h3>Familiar with -</h3>
          <ul className="techskills">
            <li>Python</li>
            <li>LangChain & OpenAI</li>
            <li>Object-Oriented Programming</li>
            <li>Node.js & Express</li>
            <li>Docker & Lando</li>
            <li>CircleCI/CD</li>
            <li>jQuery</li>
            <li>MySQL</li>
          </ul>

          <h3>Some exposure to -</h3>
          <ul className="techskills">
            <li>React</li>
            <li>Laravel</li>
            <li>Drupal</li>
            <li>Gatsby.js</li>
            <li>GraphQL</li>
            <li>PostgreSQL</li>
            <li>MongoDB</li>
          </ul>

          {close}
        </article>

        <article
          id="resume"
          className={`${this.props.article === 'resume' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
           <h2 className="major">Resume & Portfolio</h2>

        <h3>Resume</h3>
        <p>
          <a href="/static/DanScott-resume-Oct2023.pdf" target="_blank">View my Resume (Oct 2023 Update)&nbsp;&nbsp;<i class="icon fa-file-pdf-o" style={{fontSize: '110%', background: 'none'}}></i></a>
        </p>

        <h3>Professional Portfolio</h3>
        <p>
          All of my professional work lives in private Github repos, that are not shareable for obvious reasons. Please see my resume for a sampling of some of the projects I've worked on. If you'd like to hear more about something specific, feel free to reach out!
        </p>

        <hr/>

        <h3>Personal Projects</h3>

        <h4>Current Project: GigGenius</h4>
        <p>
          A web app built on Python and Vue.js that leverages LangChain/OpenAI to speed up the process of finding freelance jobs relevant to your skillset, empowering freelancers to spend less time searching, and more time earning. Ongoing work-in-progress as of October 2023.<br/>
          GitHub: <a href="https://github.com/DanScott2150/giggenius" target="_blank">https://github.com/DanScott2150/giggenius</a> | Live Demo: (coming soon...)
        </p>

        <hr/>
       <h3>Old/Defunct Personal Projects</h3>

       <p>Please note that most of these are many years old and likely in a state of disrepair. Many of these were created early in my development career.</p>

        <h4>PolicyBytes</h4>

        <span class="image main">
          <img src="/static/policybytes1.jpg" />
        </span>

        <p>PolicyBytes is a React-based SPA that provides a platform for multi-threaded, evidence-based debate. This was an initial prototype project that was built for a friend who works at a nonprofit organization focused on public policy and voter education.</p>

        <span class="image main">
        <img src="/static/policybytes2.jpg" />
        </span>
        <p>The app includes a custom-built CMS that allows a non-technical administrator to update and manage the app's content.</p>

        {/* <a href="https://policybytes-beta.herokuapp.com/" target="_blank" style={{borderBottom: 0}}><button>Live Demo&nbsp;&nbsp;<i class="icon fa-external-link"></i></button></a> */}
        &nbsp;&nbsp;&nbsp;
        <a href="https://github.com/DanScott2150/policybytes" target="_blank" style={{borderBottom: 0}}><button>GitHub&nbsp;&nbsp;<i class="icon fa-github"></i></button></a>


<hr/>

<h4>Massachusetts Fly-Fishing Dashboard</h4>
<span class="image main">
        <img src="/static/fishing1.jpg" />
        </span>
<p>Node.js app that displays current information regarding fishing conditions for popular fly fishing rivers in Western Massachusetts. All data is dynamically-updated and pulled from various third-party APIs.</p>
<span class="image main">
        <img src="/static/fishing2.jpg" />
        </span>

<p>Selecting a river will show more in-depth information, including weather forecasts, flow rates, and trout stocking reports. Each river page also features a user-editable "Notes & Research" section, as well as an interactive map where the user can save points of interest.</p>

        <span class="image main">
        <img src="/static/fishing3.jpg" />
        </span>

        <p>An interactive Google Map allows the user to easily add additional rivers to the dashboard. The map features a customized overlay, which highlights rivers that get stocked with trout by the state, as well as triangle icons to indicate USGS data stations.</p>

        <a href="https://github.com/DanScott2150/Massachusetts-Fly-Fishing-Dashboard" target="_blank" style={{borderBottom: 0}}><button>GitHub&nbsp;&nbsp;<i class="icon fa-github"></i></button></a>

        <hr/>


        <h4>StateDroneLaw.com</h4>
        <span class="image main">
        <img src="/static/sdl1.jpg" />
        </span>

        <p>StateDroneLaw.com was my first major web development project, and featured a customized theme that I built from scratch using PHP and CSS/SASS. The site also featured custom-built post types and taxonomies. The site's back-end integrated with an auto-updater app (separate project, see below) which streamlined the process of keeping the site's content up-to-date.</p>
        <span class="image main">
        <img src="/static/sdl2.jpg" />
        </span>
        <a href="https://github.com/DanScott2150/StateDroneLaw" target="_blank" style={{borderBottom: 0}}><button>GitHub&nbsp;&nbsp;<i class="icon fa-github"></i></button></a>


        <hr/>

        <h4>StateDroneLaw Auto-Updater App</h4>

        <span class="image main">
        <img src="/static/updater1.jpg" />
        </span>

        <p>Node.js App I developed to help automate the process of keeping StateDroneLaw.com up-to-date. Scrapes a third-party API (OpenStates.org) to compile a list of drone-related laws (including both current laws and proposed legislation) across all 50 state legislatures. The resulting list of laws are then cross-referenced against StateDroneLaw.com in order to identify "new" laws that need to be added to the site, vs. laws that are already listed on the site.</p>

        <span class="image main">
        <img src="/static/updater2.jpg" />
        </span>

        <p>Clicking "ADD" on a new law brings the user to an input form, which is auto-populated with the data returned from the OpenStates API call.</p>
        <span class="image main">
        <img src="/static/updater3.jpg" />
        </span>

        <p>The app integrates with StateDroneLaw.com via the WordPress REST API (using OAuth authentication), allowing new laws to be published to the live site from within the app, effectively rendering WordPress as a headless CMS.</p>


        <a href="https://github.com/DanScott2150/StateDroneLaw-AutoUpdater" target="_blank" style={{borderBottom: 0}}><button>GitHub&nbsp;&nbsp;<i class="icon fa-github"></i></button></a>

         {close}
        </article>






        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <h3><strong>I'd love to hear from you!</strong></h3>
          <p>Want to learn more? The best way to get in touch with me is via email or a LinkedIn message. </p>

          <ul className="icons">
            <li>
              <a href="mailto:danscott2150@gmail.com" className="icon fa-envelope-o"><span className="label">Email</span></a>
            </li>
            <a href="mailto:danscott2150@gmail.com">Email: DanScott2150 (at) gmail (dot) com</a>
            <br />
            <br />

            <li>
              <a href="https://www.linkedin.com/in/danscott2150/" target="_blank" rel="noopener noreferrer" className="icon fa-linkedin"><span className="label">LinkedIn</span></a>
            </li>
            <a href="https://www.linkedin.com/in/danscott2150/" target="_blank" rel="noopener noreferrer">LinkedIn : @DanScott2150</a>

            <br />
            <br />

            <li><a href="https://www.github.com/danscott2150" className="icon fa-github"><span className="label">GitHub</span></a></li>
            <a href="https://www.github.com/danscott2150" target="_blank" rel="noopener noreferrer">GitHub (personal): @DanScott2150</a>

            <br/><br/>

            <li><a href="https://www.github.com/danscott2150" className="icon fa-github"><span className="label">GitHub</span></a></li>
            <a href="https://www.github.com/dscottnu" target="_blank" rel="noopener noreferrer">GitHub (professional): @DScottNU</a>

            <br />
            <br />

            <hr></hr>
            <li><a href="/static/DanScott-resume-Oct2023.pdf" target="_blank" rel="noopener noreferrer" className="icon fa-file-pdf-o"><span className="label">Resume</span></a></li>
            <a href="/static/DanScott-resume-Oct2023.pdf" target="_blank" rel="noopener noreferrer">View My Resume (.pdf)</a>
            <br/><br/>
            {/* <li><a href="/static/DanScott-Portfolio-Feb2023.pdf" target="_blank" rel="noopener noreferrer" className="icon fa-file-pdf-o"><span className="label">Portfolio</span></a></li>
            <a href="/static/DanScott-Portfolio-Feb2023.pdf" target="_blank" rel="noopener noreferrer">View My Portfolio (.pdf)</a> */}

            <hr></hr>



          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
